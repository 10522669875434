<template>
  <div class="superadmin-dashboard bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen p-8">
    <div class="max-w-6xl mx-auto">
      <h1 class="text-4xl font-extrabold mb-8 text-indigo-800 border-b-4 border-indigo-500 pb-2 inline-block">
        Superadmin Dashboard
      </h1>
      
      <div class="bg-white rounded-2xl shadow-xl p-8 mb-8 transition-all duration-300 hover:shadow-2xl">
        <h2 class="text-2xl font-bold mb-6 text-indigo-700 flex items-center">
          Manage Clients
        </h2>
        
        <!-- Client List -->
        <div v-if="clients.length" class="mb-8">
          <div class="overflow-x-auto bg-white rounded-xl shadow-md">
            <table class="min-w-full leading-normal">
              <thead>
                <tr class="bg-indigo-50">
                  <th class="px-5 py-3 border-b-2 border-indigo-200 text-left text-xs font-semibold text-indigo-600 uppercase tracking-wider">Name</th>
                  <th class="px-5 py-3 border-b-2 border-indigo-200 text-left text-xs font-semibold text-indigo-600 uppercase tracking-wider">Client ID</th>
                  <th class="px-5 py-3 border-b-2 border-indigo-200 text-left text-xs font-semibold text-indigo-600 uppercase tracking-wider">Status</th>
                  <th class="px-5 py-3 border-b-2 border-indigo-200 text-left text-xs font-semibold text-indigo-600 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="client in clients" :key="client.id" class="hover:bg-indigo-50 transition-colors duration-200">
                  <td class="px-5 py-5 border-b border-gray-200 text-sm">
                    <p class="text-gray-900 whitespace-no-wrap font-medium">{{ client.name }}</p>
                  </td>
                  <td class="px-5 py-5 border-b border-gray-200 text-sm">
                    <p class="text-gray-600 whitespace-no-wrap">{{ client.clientId }}</p>
                  </td>
                  <td class="px-5 py-5 border-b border-gray-200 text-sm">
                    <span :class="client.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'" class="px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full">
                      {{ client.isActive ? 'Active' : 'Inactive' }}
                    </span>
                  </td>
                  <td class="px-5 py-5 border-b border-gray-200 text-sm">
                    <button @click="toggleClientStatus(client)" class="text-blue-600 hover:text-blue-900 mr-3 transition-colors duration-200">
                      {{ client.isActive ? 'Deactivate' : 'Activate' }}
                    </button>
                    <button @click="editClient(client)" class="text-green-600 hover:text-green-900 mr-3 transition-colors duration-200">Edit</button>
                    <button @click="deleteClient(client)" class="text-red-600 hover:text-red-900 transition-colors duration-200">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
                <!-- Create/Edit Client Form -->
                <div v-if="showForm" class="client-form bg-white shadow-xl rounded-xl p-8 mb-8 border border-indigo-100 transition-all duration-300 hover:shadow-2xl">
          <h3 class="text-2xl font-bold mb-6 text-indigo-800 border-b pb-2">{{ editingClient ? 'Edit Client' : 'Create New Client' }}</h3>
          <form @submit.prevent="submitClientForm" class="space-y-6">
            <div>
              <label class="block text-indigo-700 text-sm font-bold mb-2" for="clientName">Name:</label>
              <input id="clientName" v-model="clientForm.name" required class="shadow-sm appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-200">
            </div>
            <div>
              <label class="block text-indigo-700 text-sm font-bold mb-2" for="clientRedirectUris">Redirect URIs:</label>
              <textarea id="clientRedirectUris" v-model="clientForm.redirectUris" required rows="3" class="shadow-sm appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-200"></textarea>
              <p class="text-indigo-600 text-xs italic mt-1">Enter each URI on a new line</p>
            </div>
            <div>
              <label class="block text-indigo-700 text-sm font-bold mb-2">Grants:</label>
              <div class="space-y-2">
                <label v-for="grant in availableGrants" :key="grant" class="inline-flex items-center mr-4">
                  <input type="checkbox" v-model="clientForm.grants" :value="grant" class="form-checkbox h-5 w-5 text-indigo-600 rounded">
                  <span class="ml-2 text-gray-700">{{ grant }}</span>
                </label>
              </div>
            </div>
            <div>
              <label class="block text-indigo-700 text-sm font-bold mb-2" for="clientScope">Scope:</label>
              <input id="clientScope" v-model="clientForm.scope" class="shadow-sm appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-200">
              <p class="text-indigo-600 text-xs italic mt-1">Separate multiple scopes with spaces</p>
            </div>
            <div>
              <label class="inline-flex items-center">
                <input type="checkbox" v-model="clientForm.isActive" class="form-checkbox h-5 w-5 text-indigo-600 rounded">
                <span class="ml-2 text-gray-700">Active</span>
              </label>
            </div>
            <div class="flex items-center justify-end space-x-4">
              <button type="button" @click="cancelForm" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded-lg focus:outline-none focus:shadow-outline transition duration-200 transform hover:scale-105">
                Cancel
              </button>
              <button type="submit" class="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-6 rounded-lg focus:outline-none focus:shadow-outline transition duration-200 transform hover:scale-105">
                {{ editingClient ? 'Update' : 'Create' }} Client
              </button>
            </div>
          </form>
        </div>

        <button v-if="!showForm" @click="showCreateForm" class="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline transition duration-200 transform hover:scale-105 flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
          Create New Client
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import axiosInstance from '../api/axios';

export default {
  setup() {
    const clients = ref([]);
    const showForm = ref(false);
    const editingClient = ref(null);
    const availableGrants = ['authorization_code', 'refresh_token', 'client_credentials'];
    const clientForm = reactive({
      name: '',
      redirectUris: '',
      grants: [],
      scope: '',
      isActive: true
    });

    const fetchClients = async () => {
      try {
        const response = await axiosInstance.get('/clients');
        clients.value = response.data;
      } catch (error) {
        console.error('Error fetching clients:', error);
        // TODO: Add error handling, e.g., show error message to user
      }
    };

    const toggleClientStatus = async (client) => {
      try {
        await axiosInstance.post(`/clients/${client.id}/toggle-status`);
        await fetchClients(); // Refresh the list
      } catch (error) {
        console.error('Error toggling client status:', error);
        // TODO: Add error handling, e.g., show error message to user
      }
    };

    const showCreateForm = () => {
      showForm.value = true;
      editingClient.value = null;
      Object.assign(clientForm, {
        name: '',
        redirectUris: '',
        grants: [],
        scope: '',
        isActive: true
      });
    };

    const editClient = (client) => {
      showForm.value = true;
      editingClient.value = client;
      Object.assign(clientForm, {
        name: client.name,
        redirectUris: client.redirectUris.join('\n'),
        grants: client.grants,
        scope: client.scope.join(' '),
        isActive: client.isActive
      });
    };

    const cancelForm = () => {
      showForm.value = false;
      editingClient.value = null;
    };

    const submitClientForm = async () => {
      try {
        const formData = {
          name: clientForm.name,
          redirectUris: clientForm.redirectUris.split('\n').map(uri => uri.trim()),
          grants: clientForm.grants,
          scope: clientForm.scope.split(' ').map(scope => scope.trim()),
          isActive: clientForm.isActive
        };
        alert(clientForm.scope.split(' ').map(scope => scope.trim()));
        if (editingClient.value) {
          await axiosInstance.put(`/clients/${editingClient.value.id}`, formData);
        } else {
          await axiosInstance.post('/clients', formData);
        }
        await fetchClients();
        showForm.value = false;
      } catch (error) {
        console.error('Error submitting client form:', error);
        // TODO: Add error handling, e.g., show error message to user
      }
    };

    const deleteClient = async (client) => {
      if (confirm(`Are you sure you want to delete client "${client.name}"?`)) {
        try {
          await axiosInstance.delete(`/clients/${client.id}`);
          await fetchClients();
        } catch (error) {
          console.error('Error deleting client:', error);
          // TODO: Add error handling, e.g., show error message to user
        }
      }
    };

    return { 
      clients, 
      showForm,
      clientForm,
      availableGrants,
      toggleClientStatus, 
      showCreateForm,
      editClient,
      cancelForm,
      submitClientForm,
      deleteClient
    };
  }
}
</script>

<style scoped>
.superadmin-dashboard {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.client-form {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
}

.client-form div {
  margin-bottom: 10px;
}

label {
  display: inline-block;
  width: 100px;
}

input {
  width: 200px;
  padding: 5px;
}

button {
  margin-right: 10px;
  padding: 5px 10px;
  cursor: pointer;
}
</style>