import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ChangePassword from '../views/ChangePassword.vue';
import Callback from '../views/Callback.vue';
import SuperadminDashboard from '@/views/SuperadminDashboard.vue';
import store from '../store';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/superadmin',
    name: 'SuperadminDashboard',
    component: SuperadminDashboard,
    meta: { requiresAuth: true }
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiresAuth: true }
  },
  {
    path: '/callback',
    component: Callback
  },
  {
    path: '/',
    redirect: '/dashboard'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresSuperAdmin = to.matched.some(record => record.meta.requiresSuperAdmin);

  if (requiresAuth && !store.getters.isAuthenticated) {
    next('/login');
  } else if (requiresSuperAdmin && !store.getters.isSuperAdmin) {
    next('/unauthorized');
  } else {
    next();
  }
});

export default router;