<template>
  <div class="forgot-password-container">
    <div class="forgot-password-card">
      <h2>Forgot Password</h2>
      <p class="instruction">Enter your email address and we'll send you a link to reset your password.</p>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="email" required placeholder="Enter your email">
        </div>
        <button type="submit" class="reset-button" :disabled="isLoading">
          {{ isLoading ? 'Sending...' : 'Send Reset Link' }}
        </button>
      </form>
      <p v-if="message" :class="{'success': !error, 'error': error}">{{ message }}</p>
      <div class="additional-options">
        <router-link to="/login" class="back-to-login">
          <span class="arrow">&larr;</span> Back to Login
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ForgotPassword',
  setup() {
    const store = useStore();
    const email = ref('');
    const message = ref('');
    const error = ref(false);
    const isLoading = ref(false);

    const handleSubmit = async () => {
      isLoading.value = true;
      message.value = '';
      error.value = false;

      try {
        // Assuming you have a 'forgotPassword' action in your Vuex store
        await store.dispatch('forgotPassword', email.value);
        message.value = 'Password reset link sent to your email. Please check your inbox.';
        error.value = false;
      } catch (err) {
        message.value = 'Failed to send reset link. Please try again.';
        error.value = true;
      } finally {
        isLoading.value = false;
      }
    };

    return {
      email,
      message,
      error,
      isLoading,
      handleSubmit
    };
  }
}
</script>

<style scoped>
.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.forgot-password-card {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  text-align: center;
}

.instruction {
  color: #666;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

input:focus {
  outline: none;
  border-color: #4CAF50;
}

.reset-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reset-button:hover:not(:disabled) {
  background-color: #45a049;
}

.reset-button:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.success {
  color: #52c41a;
  margin-top: 1rem;
  text-align: center;
  padding: 10px;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: 4px;
}

.error {
  color: #ff4d4f;
  margin-top: 1rem;
  text-align: center;
  padding: 10px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
}

.additional-options {
  margin-top: 1.5rem;
  text-align: center;
}

.back-to-login {
  color: #1890ff;
  text-decoration: none;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  transition: color 0.3s;
}

.back-to-login:hover {
  color: #40a9ff;
}

.arrow {
  margin-right: 5px;
  font-size: 1.1rem;
}
</style>