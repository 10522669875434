import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    user: null,
    token: null,
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    currentSolution: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setTokens(state, { accessToken, refreshToken }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    },
    setCurrentSolution(state, solution) {
      state.currentSolution = solution;
    },
    clearAuth(state) {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.currentSolution = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
  },
  actions: {
    async login({ commit }, { email, password, solution }) {
      try {
        const response = await axios.post('/api/auth/login', { email, password, solution });
        const { user, accessToken, refreshToken } = response.data;
        commit('setUser', user);
        commit('setTokens', { accessToken, refreshToken });
        commit('setCurrentSolution', solution);
        // Optionally fetch full profile if needed
        // await dispatch('fetchUserProfile');
        return response.data;
      } catch (error) {
        //console.error('Login error:', error.response.data.message);
        throw error.response.data.message;
      }
    },
    async fetchUserProfile({ commit, state }) {
      alert(state.refreshToken);

      if (!state.accessToken) {
        throw new Error('No access token available');
      }
      try {
        const response = await axios.get('/api/auth/profile', {
          headers: { Authorization: `Bearer ${state.accessToken}` }
        });
        commit('setUser', response.data);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
        throw error;
      }
    },
    async refreshToken({ commit, state }) {
      if (!state.refreshToken) {
        throw new Error('No refresh token available');
      }
      try {
        const response = await axios.post('/api/auth/refresh-token', {
          refreshToken: state.refreshToken
        });
        const { accessToken, refreshToken } = response.data;
        commit('setTokens', { accessToken, refreshToken });
        return { accessToken, refreshToken };
      } catch (error) {
        console.error('Token refresh error:', error);
        commit('clearAuth');
        throw error;
      }
    },
    logout({ commit }) {
      // Optionally, you could also make an API call to invalidate the token on the server
      commit('clearAuth');
    },
  },
  async changePassword({ state }, { currentPassword, newPassword }) {
    try {
      await axios.post('/api/auth/change-password', 
        { currentPassword, newPassword },
        { headers: { Authorization: `Bearer ${state.accessToken}` } }
      );

      // If you want to do something after successful password change, do it here
      // For example, you could fetch the user profile again
      // await dispatch('fetchUserProfile');
    } catch (error) {
      console.error('Change password error:', error);
      throw error;
    }
  },
  getters: {
    isAuthenticated: state => !!state.accessToken,
    isSuperAdmin: state => state.user && state.user.isSuperAdmin,
    currentUser: state => state.user,
    currentSolution: state => state.currentSolution,
  }
});