

<template>
  <div class="login-container">
    <div class="login-card">
      <h2>Welcome to {{ solution }}</h2>
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="email" required placeholder="Enter your email">
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" required placeholder="Enter your password">
        </div>
        <button type="submit" class="login-button" :disabled="isLoading">
          {{ isLoading ? 'Logging in...' : 'Log In' }}
        </button>
      </form>
      <p v-if="error" class="error">{{ error }}</p>
      <div class="additional-options">
        <router-link to="/forgot-password" class="forgot-password">Forgot Password?</router-link>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'LogIn',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const email = ref('');
    const password = ref('');
    const error = ref('');
    const isLoading = ref(false);
    const solution = ref('');

    onMounted(() => {
      solution.value = !window.opener?'IVIS LABS':route.query.solution || 'Default';
    });

    const handleLogin = async () => {
      isLoading.value = true;
      error.value = '';
      try {
        const result = await store.dispatch('login', { 
          email: email.value, 
          password: password.value,
          solution: solution.value
        });
        
        if (window.opener) {
         
          // We're in a popup, send message to opener and close
          window.opener.postMessage({ type: 'AUTH_SUCCESS', token: result.accessToken, user: result.user }, '*');
          window.close();
        } else {
          // Not in a popup, navigate as usual
          router.push('/dashboard');
        }
      } catch (err) {
        error.value = 'Invalid email or password';
      } finally {
        isLoading.value = false;
      }
    };

    return { 
      email, 
      password, 
      error, 
      isLoading, 
      solution, 
      handleLogin 
    };
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
}

.login-card {
  background-color: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  transition: all 0.3s ease;
}

.login-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

h2 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: 500;
  font-size: 0.9rem;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #667eea;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #667eea;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-button:hover:not(:disabled) {
  background-color: #5a6fd6;
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error {
  color: #ff4d4f;
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  padding: 0.5rem;
  border-radius: 4px;
}

.additional-options {
  margin-top: 1.5rem;
  text-align: center;
}

.forgot-password {
  color: #667eea;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.forgot-password:hover {
  color: #5a6fd6;
  text-decoration: underline;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>