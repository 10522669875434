<template>
  <div class="change-password-container">
    <div class="change-password-card">
      <h2>Change Password</h2>
      <form @submit.prevent="handleChangePassword">
        <div class="form-group">
          <label for="currentPassword">Current Password</label>
          <input type="password" id="currentPassword" v-model="currentPassword" required>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password</label>
          <input type="password" id="newPassword" v-model="newPassword" required>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm New Password</label>
          <input type="password" id="confirmPassword" v-model="confirmPassword" required>
        </div>
        <button type="submit" class="change-password-button" :disabled="isLoading">
          {{ isLoading ? 'Changing...' : 'Change Password' }}
        </button>
      </form>
      <p v-if="message" :class="{ 'success-message': !error, 'error-message': error }">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ChangePassword',
  setup() {
    const store = useStore();
    const currentPassword = ref('');
    const newPassword = ref('');
    const confirmPassword = ref('');
    const message = ref('');
    const error = ref(false);
    const isLoading = ref(false);

    const handleChangePassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        message.value = 'New passwords do not match.';
        error.value = true;
        return;
      }

      isLoading.value = true;
      message.value = '';
      error.value = false;

      try {
        await store.dispatch('changePassword', {
          currentPassword: currentPassword.value,
          newPassword: newPassword.value
        });
        message.value = 'Password changed successfully.';
        error.value = false;
        currentPassword.value = '';
        newPassword.value = '';
        confirmPassword.value = '';
      } catch (err) {
        message.value = 'Failed to change password. Please try again.';
        error.value = true;
      } finally {
        isLoading.value = false;
      }
    };

    return {
      currentPassword,
      newPassword,
      confirmPassword,
      message,
      error,
      isLoading,
      handleChangePassword
    };
  }
}
</script>

<style scoped>
.change-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.change-password-card {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

h2 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

input:focus {
  outline: none;
  border-color: #4CAF50;
}

.change-password-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.change-password-button:hover:not(:disabled) {
  background-color: #45a049;
}

.change-password-button:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.success-message, .error-message {
  margin-top: 1rem;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}

.success-message {
  color: #52c41a;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.error-message {
  color: #ff4d4f;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
</style>