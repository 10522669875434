import axios from 'axios';
import store from '../store';

const axiosInstance = axios.create({
  baseURL: 'https://authbackend.ivislabs.com/api', // adjust this to your API URL
});

axiosInstance.interceptors.request.use(
  config => {
    const token = store.state.accessToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await store.dispatch('refreshToken');
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;