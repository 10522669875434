<template>
  <div class="dashboard" v-if="isLoaded">
    <h1>Welcome, {{ username }}!</h1>
    <p v-if="currentSolution">
      Your current solution: {{ currentSolution }} (Role: {{ solutionRoles }})
    </p>
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'DashBoard',
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoaded = ref(false);

    const username = computed(() => store.state.user?.username || 'User');
    const currentSolution = computed(() => store.state.user?.solution);
    const solutionRoles = computed(() => store.state.user?.role || []);
    onMounted(async () => {
      
      if (!store.getters['isAuthenticated'] || username.value=='User') {
        await store.dispatch('logout');
        router.push('/login');
      } else {
        if (!store.state.user) {
          try {
            await store.dispatch('fetchUserProfile');
          } catch (error) {
            console.error('Failed to fetch user profile:', error);
            // Handle error (e.g., show error message, redirect to login)
          }
        }
        isLoaded.value = true;
      }
    });

    return {
      isLoaded,
      username,
      currentSolution,
      solutionRoles
    };
  }
}
</script>