import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

const app = createApp(App)

// Set up axios defaults
axios.defaults.baseURL = 'https://authbackend.ivislabs.com/'

// Function to get the current access token
const getAccessToken = () => {
  return store.state.auth && store.state.auth.accessToken
    ? store.state.auth.accessToken
    : null
}

// Axios request interceptor
axios.interceptors.request.use(config => {
  const token = getAccessToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, error => {
  return Promise.reject(error)
})

// Axios response interceptor
axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      try {
        await store.dispatch('auth/refreshToken')
        const newToken = getAccessToken()
        if (newToken) {
          originalRequest.headers.Authorization = `Bearer ${newToken}`
        }
        return axios(originalRequest)
      } catch (refreshError) {
        // If token refresh fails, logout the user and redirect to login page
        await store.dispatch('auth/logout')
        router.push('/login')
        return Promise.reject(refreshError)
      }
    }
    return Promise.reject(error)
  }
)

// Use plugins
app.use(router)
app.use(store)

// Global error handler
app.config.errorHandler = (err, vm, info) => {
  console.error('Global error:', err, info)
  // You can add more error handling logic here, like sending to an error tracking service
}

// Initialize auth state from localStorage if available
store.dispatch('auth/initializeAuth')

// Mount the app
app.mount('#app')