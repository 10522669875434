<template>
    <div>Processing authentication...</div>
  </template>
  
  <script>
  import { onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  
  export default {
    name: 'CallBack',
    setup() {
      const router = useRouter();
      const store = useStore();
  
      onMounted(() => {
        const hash = window.location.hash.substr(1);
        const result = hash.split('&').reduce((result, item) => {
          const parts = item.split('=');
          result[parts[0]] = decodeURIComponent(parts[1]);
          return result;
        }, {});
  
        if (result.access_token && result.state === localStorage.getItem('auth_state')) {
          store.commit('setToken', result.access_token);
          localStorage.removeItem('auth_state');
          router.push('/dashboard');
        } else {
          console.error('Invalid authentication result');
          router.push('/login');
        }
      });
    }
  }
  </script>