<template>
  <div id="app">
    <header>
      <nav>
        <div class="nav-container">
          <RouterLink to="/" class="logo">IVIS LABS</RouterLink>
          <div class="nav-links" :class="{ 'active': isMenuOpen }">
            <RouterLink to="/" @click="closeMenu">Home</RouterLink>
            <RouterLink v-if="!isAuthenticated" to="/login" @click="closeMenu">Login</RouterLink>
            <RouterLink v-if="isSuperAdmin" to="/superadmin" @click="closeMenu">Superadmin</RouterLink>
            <div v-if="isAuthenticated" class="profile-menu">
              <button @click="toggleProfileMenu" class="profile-icon">👤</button>
              <div v-if="isProfileMenuOpen" class="profile-dropdown">
                <RouterLink to="/profile" @click="closeMenu">Profile</RouterLink>
                <RouterLink to="/change-password" @click="closeMenu">Change Password</RouterLink>
                <a href="#" @click="handleLogout">Logout</a>
              </div>
            </div>
          </div>
          <button class="menu-toggle" @click="toggleMenu">
            ☰
          </button>
        </div>
      </nav>
    </header>

    <main>
      <RouterView/>
    </main>

    <footer>
      <p>&copy; {{ currentYear }} IVIS LABS Private Limited</p>
    </footer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, onErrorCaptured } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const error = ref(null)
const isMenuOpen = ref(false)
const isProfileMenuOpen = ref(false)

const isAuthenticated = computed(() => store.getters.isAuthenticated)
const isSuperAdmin = computed(() => store.getters.isSuperAdmin)
const currentYear = computed(() => new Date().getFullYear())

const handleLogout = async () => {
  try {
    await store.dispatch('logout')
    router.push('/login')
  } catch (err) {
    console.error('Logout error:', err)
  } finally {
    closeMenu()
    closeProfileMenu()
  }
}

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
  if (isMenuOpen.value) {
    closeProfileMenu()
  }
}

const closeMenu = () => {
  isMenuOpen.value = false
}

const toggleProfileMenu = () => {
  isProfileMenuOpen.value = !isProfileMenuOpen.value
}

const closeProfileMenu = () => {
  isProfileMenuOpen.value = false
}

const handleResize = () => {
  if (window.innerWidth > 768) {
    isMenuOpen.value = false
    isProfileMenuOpen.value = false
  }
}

const handleClickOutside = (event) => {
  const profileMenu = document.querySelector('.profile-menu')
  if (profileMenu && !profileMenu.contains(event.target)) {
    closeProfileMenu()
  }
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  document.removeEventListener('click', handleClickOutside)
})

onErrorCaptured((err) => {
  error.value = err
  console.error(err)
  return true
})
</script>

<style>
:root {
  --primary-color: #2c3e50;
  --background-color: #ffffff;
  --accent-color: #42b983;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--primary-color);
  background-color: var(--background-color);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--primary-color);
}

.nav-links {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
}

.nav-links a:hover,
.nav-links a.router-link-exact-active {
  color: var(--accent-color);
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.profile-menu {
  position: relative;
}

.profile-icon {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.profile-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.profile-dropdown a {
  padding: 0.5rem 1rem;
  text-align: left;
}

@media (max-width: 768px) {
  .nav-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    padding-top: 1rem;
  }

  .nav-links.active {
    display: flex;
  }

  .menu-toggle {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .profile-dropdown {
    position: static;
    border: none;
    padding: 0;
  }

  .profile-dropdown a {
    padding: 0.5rem 0;
  }
}

footer {
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--background-color);
}

main {
  min-height: calc(100vh - 120px); /* Adjust based on your header and footer height */
  padding: 20px;
}

.nav-links a, .profile-dropdown a {
  transition: color 0.3s ease;
}

.profile-icon:hover {
  opacity: 0.8;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.profile-dropdown {
  animation: fadeIn 0.3s ease;
}
</style>